<template>
  <div v-if="property">
    <!-- <SEO title="{{ formatStreetName(property.addressBase) }}" /> -->
    <banner
      link="/properties"
      link-icon="chevron-left"
      link-text="My Properties"
      :title="formatStreetName(property.addressBase)"
      :text="formatAddressWithoutStreetName(property.addressBase)"
    />
    <div class="container py-5">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="pull">
            <recently-completed-work :lodgements="property.lodgements" />
          </div>
          <h2>About this property</h2>
          <div class="row mb-5">
            <div class="col-lg-6">
              <template v-if="property.propertyInformation">
                <h3>Property information</h3>
                <dl>
                  <dt v-if="property.propertyInformation.propertyType">Property type</dt>
                  <dd v-if="property.propertyInformation.propertyType">
                    {{ property.propertyInformation.propertyType }}
                  </dd>
                  <dt v-if="property.propertyInformation.propertyDetachment">Detachment</dt>
                  <dd v-if="property.propertyInformation.propertyDetachment">
                    {{ property.propertyInformation.propertyDetachment }}
                  </dd>
                  <dt v-if="property.propertyInformation.propertyAge">Age</dt>
                  <dd v-if="property.propertyInformation.propertyAge">
                    {{ property.propertyInformation.propertyAge }}
                  </dd>
                  <dt v-if="property.propertyInformation.propertyConstruction">Construction</dt>
                  <dd v-if="property.propertyInformation.propertyConstruction">
                    {{ property.propertyInformation.propertyConstruction }}
                  </dd>
                  <dt v-if="property.propertyInformation.propertyBedrooms">Bedrooms</dt>
                  <dd v-if="property.propertyInformation.propertyBedrooms">
                    {{ property.propertyInformation.propertyBedrooms }}
                  </dd>
                </dl>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SEO from './SEO.vue'
import RecentlyCompletedWork from './RecentlyCompletedWork.vue';
import Banner from './Banner.vue';
import { formatStreetName, formatAddressWithoutStreetName } from '../helpers/address';

export default {
  name: 'Property',
  components: {
    // SEO,
    RecentlyCompletedWork,
    Banner,
  },
  data() {
    return {
      errorMessage: null,
    };
  },
  computed: {
    property() {
      return this.$store.state.homeOwner.property;
    },
    authenticated() {
      return this.$store.state.auth.authenticated;
    },
    uprn() {
      return this.$route.params.uprn;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (!this.property) {
      this.$store.dispatch('homeOwner/getProperty', this.$route.params.uprn).catch((err) => {
        if (err.response && err.response.status === 404) {
          this.$router.push('/property-not-found');
          return;
        }
        this.errorMessage = err;
      });
    }
  },
  methods: {
    formatStreetName,
    formatAddressWithoutStreetName,
  },
};
</script>
