<template>
  <div>
    <h2>Recently completed work</h2>
    <template v-if="lodgements.length">
      <div v-if="isMobile" class="d-md-none">
        <ul class="list-group list-group-flush mb-0">
          <li v-for="(lodgement, index) in lodgements" :key="index" class="list-group-item mx-0">
            <h3 class="mb-0">
              <router-link :to="`/property/${lodgement.propertyUPRN}/${lodgement.id}`">{{
                lodgement.certificateLines.map((x) => x.workType).join(', ')
              }}</router-link>
            </h3>
            <p class="mb-0">
              {{ lodgement.business.businessName }}
              <br />
              <span class="small">{{ moment(lodgement.completeDt).format('Do MMMM YYYY') }}</span>
            </p>
          </li>
        </ul>
      </div>
      <div v-else class="d-none d-md-block">
        <table class="table">
          <thead>
            <tr>
              <th>Work completed</th>
              <th>Lodged by</th>
              <th>Lodgement date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lodgement, index) in lodgements" :key="index">
              <td width="40%">
                {{ lodgement.certificateLines.map((x) => x.workType).join(', ') }}
              </td>
              <td>{{ lodgement.business.businessName }}</td>
              <td>{{ moment(lodgement.completeDt).format('Do MMMM YYYY') }}</td>
              <td>
                <router-link :to="`/property/${lodgement.propertyUPRN}/${lodgement.id}`"
                  ><font-awesome-icon :icon="['fas', 'chevron-right']"
                /></router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'RecentlyCompletedWork',
  components: {
    FontAwesomeIcon,
  },
  props: {
    lodgements: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },
  methods: {
    moment,
  },
};
</script>
